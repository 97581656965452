
import { mixins, Options } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import Notification from "@/app/ui/components/notification/index.vue";
import OverlayPanel from "primevue/overlaypanel";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { HandoverController } from "@/app/ui/controllers/HandoverController";
import InputDimension from "@/app/ui/components/input-dimension/index.vue";
import InputAdornment from "@/app/ui/components/input-adornment/index.vue";
import { BaggingController } from "@/app/ui/controllers/BaggingController";
import {
  convertDecimal,
  convertDecimalAfterComma,
  convertDecimalWithComma,
  ellipsisString,
  removeDataLocalStorage,
  storeDatatoLocalStorage
} from "@/app/infrastructures/misc/Utils";
import { ResponseUpdate } from "@/domain/entities/Handover";
import DownloadCsv from "@/app/ui/views/out-going-shipment/out-going-mixin";
import Print from "../modules/print.vue";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import debounce from "lodash/debounce";
import {
  RequestPartnerVendorList,
  UpdateApiRequest
} from "@/data/payload/api/HandoverApiRequest";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { TrackingController } from "@/app/ui/controllers/TrackingController";
import { TrackingSttData, TrackingSttDetailData } from "@/domain/entities/TrackingStt";

@Options({
  components: {
    DetailLayout,
    OverlayPanel,
    Notification,
    InputDimension,
    InputAdornment,
    Print
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess && !this.isPartial) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      next();
      this.setPartial(false);
      this.setOpenSuccess(false);
    }
  }
})
export default class UpdateHandover extends mixins(DownloadCsv) {
  mounted() {
    AccountController.checkAccountLocation();
    this.getListSubconsole();
  }
  unmounted() {
    this.form.destinationCityCode = "";
    this.setPartial(false);
    this.setOpenSuccess(false);
  }

  pagination = {
    page: 1,
    limit: 50
  };

  // delete stt
  sttNumberListTrash: Array<string> = [];
  showDeleteSttNumber = false;
  sttNumberToDelete = "";
  sttNumberSuccessDelete = "";
  errorSttNumberDelete = "";
  async onValidateDeleteSttNumber(val: any) {
    const splitVal = val.split("#");
    if (splitVal.length > 0) this.sttNumberToDelete = splitVal[0];
    this.onDeleteSttNumber();
  }
  onDeleteSttNumber() {
    if (this.sttNumberToDelete) {
      this.errorSttNumberDelete = "";
      this.sttNumberSuccessDelete = "";
      this.sttNumberToDelete = this.sttNumberToDelete.toUpperCase();

      // find index no. stt
      const indexSttNumber = this.dataSttToBeUpdate.findIndex((data: any) => {
        return data.sttNumber === this.sttNumberToDelete;
      });

      // no. stt not found
      if (indexSttNumber < 0) {
        this.errorSttNumberDelete = "No. STT tidak ditemukan/sudah terhapus";
      } else {
        this.dataSttToBeUpdate.splice(indexSttNumber, 1);
        this.sttNumberListTrash.push(this.sttNumberToDelete);
        this.sttNumberSuccessDelete = this.sttNumberToDelete;
        this.sttNumberToDelete = "";
      }
    }
    const deleteStt: any = this.$refs.deleteStt;
    deleteStt?.focus();
  }
  onShowDelete() {
    this.errorSttNumberDelete = "";
    this.showDeleteSttNumber = true;
    this.sttNumberToDelete = "";
    this.sttNumberSuccessDelete = "";
  }
  onCloseDelete() {
    this.showDeleteSttNumber = false;
    this.sttNumberListTrash = [];
  }

  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }
  get parentId() {
    return AccountController.accountData.account_type_detail.id;
  }

  // handover
  get partnerVendorList() {
    const arr = HandoverController.partnerVendorList;
    const data: Array<any> = [
      {
        name: "Vendor Internal",
        value: "vendorinternal",
        code: "vendorinternal",
        id: 0,
        type: "vendor",
        cityCode: ""
      },
      {
        name: "LUWJISTIK",
        value: "LUWJISTIK",
        code: "LUWJISTIK",
        id: 0,
        type: "vendor",
        cityCode: ""
      }
    ];
    const ids = arr.map(e => e.partnerName || e.vendorName);
    arr.forEach((item, index) => {
      const isExist = !ids.includes(
        item.partnerName || item.vendorName,
        index + 1
      );

      if (isExist) {
        data.push({
          name: item.partnerName || item.vendorName,
          value: item.code,
          code: item.code,
          id: item.partnerId,
          type: item.type,
          cityCode: item.cityCode
        });
      }
    });

    return data;
  }
  fetchPartnerVendor = debounce((search: string) => {
    if (!search.length || search.length >= 3) {
      HandoverController.getPartnerVendor(
        new RequestPartnerVendorList({
          page: 1,
          limit: 10,
          search: search
        })
      );
    }
  }, 250);
  get isLoadingPartnerVendor() {
    return HandoverController.isLoadingPartnerVendor;
  }
  onChangePartnerVendor(value: any) {
    this.form.handoverName = value;
    this.form.remarks = "";
  }

  // handle stt number
  isPaid = true;
  isUnpaidNotification = false;
  errorSttNumber = "";
  isValidateScan = false;

  onCloseNotification() {
    this.isUnpaidNotification = false;
    this.notification = false;
    this.errorSttNumber = "";
  }

  onResetForm() {
    this.form.sttNumber = "";
    this.isValidateScan = false;
    this.isErrorValidationScan = false;
    this.errorSttNumber = "";
    this.isErrorTyping = false;
    // re focus
    const inputStt: any = this.$refs.inputStt;
    inputStt.focus();
  }

  async handleScanOthers(str: string) {
    MainAppController.showLoading();
    try {
      const resp = await TrackingController.trackStt({
        sttNo: str,
        isGetSla: false,
        isSecondCall: false,
        cache: true,
      });
      if (resp) {
        return {
          sttNo: TrackingController.trackingSttData.sttDetail.sttNo,
          bagNo: "",
          noRefExternal: str,
        };
      } else {
        playNotification("error");
        this.errorSttNumber = "No. STT tidak ditemukan";
        this.isErrorValidationScan = true;
        this.isValidateScan = false;
      }
    } finally {
      TrackingController.setTrackingSttData(
        new TrackingSttData(new TrackingSttDetailData(), [])
      );
      MainAppController.closeLoading();
    }
  }
   async handleValidationSTTOthers():Promise<{ sttNo: string; bagNo: string; noRefExternal: string; }> {
    let sttNoOthers: any = { sttNo: "", bagNo:"", noRefExternal: "" };
    const isSTT = this.checkIsSTT(this.form.sttNumber);
    if (!isSTT) {
      if (this.form.sttNumber.toLowerCase().startsWith('tkp') && !this.form.sttNumber.toLowerCase().includes('bag')) { //TKP01-XXXXX
        sttNoOthers = await this.handleScanOthers(this.form.sttNumber);
      } else { //TKP01-BAG-XXX or 3LC-XXXXX
        sttNoOthers = {sttNo: "", bagNo: this.form.sttNumber, noRefExternal: ""}
      }
    }
    return sttNoOthers;
  }

  checkIsSTT(val: string) {
    return !!val.trim().match(/^\d{1,2}LP\w+$/g)
  }

  async onValidateSttNumber(val: any) {
    if (!this.isDisableAddSttNumber) {
      this.isValidateScan = true;
      const splitVal = val.split("#");
      if (splitVal.length > 0) this.form.sttNumber = splitVal[0];
      await this.addSttNumber();
    }
  }

  // add stt number
  async addSttNumber() {
    if (!this.isDisableAddSttNumber) {
      const sttNoOthers = await this.handleValidationSTTOthers();
      await HandoverController.getSttDetail({stt: !this.checkIsSTT(this.form.sttNumber) ? sttNoOthers?.sttNo : this.form.sttNumber,
        callback: this.addSttNumberToUpdate,
        bagNo: sttNoOthers.bagNo ? sttNoOthers.bagNo : "",
        partnerCode: typeof this.form.handoverName === 'object' && this.form.handoverName?.name === "LUWJISTIK" ? "LUWJISTIK": "",
        noRef: sttNoOthers?.noRefExternal});
    }
  }

  addSttNumberToUpdate(detailStt: any, noRefExternal: string) {
    if (detailStt.isAllowUpdateStatus) {

      if (detailStt.data?.length) {
        for (const detail of detailStt.data) {
          this.onValidateSttResult({isPaid : detailStt.isPaid, data: {...detail, sttNoRefExternal: ""}}, noRefExternal);
        }
      } else {
        this.onValidateSttResult(detailStt, noRefExternal);
      }

      // reset form
      if (!this.isErrorValidationScan) {
        this.isValidateScan = false;
      }

      if (this.isPaid) {
        // remove notification after 15 second
        setTimeout(() => {
          this.notification = false;
        }, 15000);
      }
            // re focus
      const inputStt: any = this.$refs.inputStt;
      inputStt.focus();
    } else {
      if (this.isValidateScan) this.isErrorValidationScan = true;
      else this.isErrorTyping = true;
      this.errorSttNumber = detailStt.errorMessage || detailStt.message?.id;
      playNotification("error");
    }
  }
  onValidateSttResult(detailStt: any, noRefExternal: string) {
    const indexCurrentSttNumber = this.dataSttToBeUpdate.findIndex(
      (data: any) => {
        return data.sttNumber === detailStt.data.sttNumber;
      }
    );
    if (indexCurrentSttNumber > -1) {
      playNotification("error");
      this.errorSttNumber = "No. STT sudah di input";
      this.isErrorValidationScan = this.isValidateScan;
      this.isErrorTyping = !this.isValidateScan;
      this.isValidateScan = false;
    } else {
      this.isPaid = detailStt.isPaid;
      this.isValidateScan = true;
      if (this.isPaid) {
        // store destination city
        this.form.destinationCityCode = detailStt.data.destinationCityId;
        // store to data table
        detailStt.data.sttNoRefExternal = noRefExternal;
        this.dataSttToBeUpdate.push(detailStt.data);
        playNotification("success");
      } else {
        this.isUnpaidNotification = !detailStt.isPaid;
        playNotification("error");
      }
      this.detailSttNotification = detailStt.data;
      this.form.sttNumber = "";
      this.notification = true;
    }
  }
  get totalStt() {
    return this.dataSttToBeUpdate.length;
  }
  get calculatedStt() {
    return this.dataSttToBeUpdate.reduce(
      (a: any, b: any) => {
        return {
          totalPieces: a.totalPieces + b.totalPieces,
          totalGrossWeight: a.totalGrossWeight + b.totalGrossWeight,
          totalVolumeWeight: a.totalVolumeWeight + b.totalVolumeWeight
        };
      },
      {
        totalPieces: 0,
        totalGrossWeight: 0,
        totalVolumeWeight: 0
      }
    );
  }
  get totalPieces() {
    return this.calculatedStt.totalPieces;
  }
  get totalGrossWeight() {
    return this.calculatedStt.totalGrossWeight;
  }
  get totalVolumeWeight() {
    return this.calculatedStt.totalVolumeWeight;
  }

  // alert popup
  isErrorTyping = false;
  isErrorValidationScan = false;
  isPartial = false;
  setPartial(value: boolean) {
    this.isPartial = value;
  }
  onDownloadCsvFailed() {
    this.downloadCsvFailedStt({
      fileName: "handover_stt_failed.csv",
      listStt: this.sttFailedList
    });
  }

  get alertPopup() {
    // if success
    if (this.openSuccess)
      return {
        onClick: () => this.onCloseSuccess(),
        title: "Perubahan Status Berhasil !",
        message: "Status STT berhasil diupdate menjadi Handover.",
        image: "image-modal-success"
      };
    // if success partially
    else if (this.isPartial)
      return {
        onClick: () => this.onCloseSuccess(),
        onDownload: () => this.onDownloadCsvFailed(),
        title: "Perubahan Status Berhasil !",
        message:
          "Beberapa status STT berhasil diupdate menjadi Handover, namun terdapat kegagalan pada beberapa STT. Silahkan download untuk mengetahui kesalahan.",
        image: "image-modal-warning"
      };
    // if input by typing
    else if (this.isErrorTyping)
      return {
        onClick: () => this.onResetForm(),
        title: "Tambah No. STT Gagal",
        message: this.errorSttNumber,
        image: "image-modal-failed"
      };
    // if input by scan
    else if (this.isErrorValidationScan)
      return {
        onClick: () => this.onResetForm(),
        title: "Scan No. STT Gagal",
        message: this.errorSttNumber,
        image: "image-modal-failed"
      };
    // default is failed
    return {
      onClick: () => this.setFailed(false),
      onDownload: () => this.onDownloadCsvFailed(),
      title: "Perubahan Status Gagal !",
      message: "Perubahan status Handover gagal dibuat.",
      image: "image-modal-failed"
    };
  }

  // form model
  form = {
    date: new Date(),
    handoverName: "" as any,
    remarks: "",
    sttNumber: "",
    destinationCityCode: ""
  };

  onlyAcceptedSomeChar(value: string) {
    const regex = /[^a-zA-Z0-9 ]/g;
    return value.replaceAll(regex, "");
  }

  get isDisableAddSttNumber() {
    return !this.form.sttNumber || this.isDisabledSave;
  }

  // SubConsol List
  getListSubconsole() {
    return HandoverController.getListSubconsole({
      parentId: Number(this.parentId)
    });
  }

  // convert value decimal
  isValueDecimal(value: number): number | string {
    return convertDecimal(value);
  }

  // format total kg
  convertTotalKg(value: number) {
    return convertDecimalAfterComma(value, 1);
  }

  // panel pickup
  togglePanel(event: any) {
    if (this.form.handoverName) {
      const refs: any = this.$refs;
      refs.op.toggle(event);
    }
  }

  // notification handler
  notification = false;
  detailSttNotification = null;

  // table
  sourceSttFromLocalStorage: any = JSON.parse(
    localStorage.getItem("handover") || "[]"
  );
  get dataSttToBeUpdate() {
    storeDatatoLocalStorage("handover", this.sourceSttFromLocalStorage);
    return this.sourceSttFromLocalStorage;
  }
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-10 text-left whitespace-no-wrap",
        render: (_: any, index: number) => {
          return `<div class="text-black-lp-300">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: `No. STT`,
        styleHead: "w-56 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${item.sttNumber}</div>`;
        }
      },
      {
        name: `No. Referensi`,
        styleHead: "w-56 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${item.sttNoRefExternal || "-"}</div>`;
        }
      },
      {
        name: "Produk",
        styleHead: "w-44 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.product}
                  </div>
                </div>`;
        }
      },
      {
        name: "Komoditas",
        styleHead: "w-64 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${ellipsisString(
            item.commodity,
            25
          )}</div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${item.totalPieces}</div>`;
        }
      },
      {
        name: "Berat Kotor",
        styleHead: "w-36 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.totalGrossWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Berat Dimensi",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.totalVolumeWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Dest.",
        styleHead: "w-20 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.destinationCityId}
                  </div>
                </div>`;
        }
      }
    ];
  }

  // role account user
  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  withPrint = false;
  countSuccess = 0;
  countFailed = 0;
  sttFailedList: Array<any> = [];
  get partnerVendorData(): any {
    return this.form.handoverName;
  }
  get isDisabledSave() {
    return (
      !this.partnerVendorData ||
      (this.partnerVendorData?.value === "vendorinternal" &&
        (!this.form.remarks ||
          this.form.remarks.length < 5 ||
          this.form.remarks.length > 200)) ||
      this.totalStt >= 250
    );
  }
  async onSave() {
    if (!this.isDisabledSave) {
      const handover: any = this.partnerVendorList.find(
        e => e.code === this.partnerVendorData?.code
      );

      const res: ResponseUpdate = await HandoverController.onUpdate(
        new UpdateApiRequest({
          destinationPartnerId: handover.id,
          destinationPartnerCode: handover.code,
          destinationPartnerType: handover.type,
          destinationCityCode: this.form.destinationCityCode,
          remarks: this.form.remarks,
          handoverSttNo: this.dataSttToBeUpdate.map((e: any) => e.sttNumber)
        })
      );
      this.handleConfirmationSave(false);

      if (res) {
        if (res.totalSttFailed)
          this.sttFailedList = res.sttFailed.map(stt => ({
            "STT Failed": stt
          }));
        // checking for update data
        if (res.totalSttSuccess && res.totalSttFailed) {
          this.countFailed = res.totalSttFailed;
          this.countSuccess = res.totalSttSuccess;
          this.isPartial = true;
        } else if (res.totalSttFailed) {
          this.countFailed = res.totalSttFailed;
          this.setFailed(true);
        } else {
          this.setOpenSuccess(true);
        }

        // with print
        if (this.withPrint) {
          const refs: any = this.$refs;
          refs.print.print(res.handoverId);
        }

        this.sourceSttFromLocalStorage = [];
      }
    }
  }

  createAndPrint(withPrint: boolean) {
    const refs: any = this.$refs;
    refs.op?.hide();
    this.withPrint = withPrint;
    this.handleConfirmationSave(true);
  }

  // handle route leave and modal
  // navigation
  get openSuccess(): boolean {
    return BaggingController.isOpenSuccess;
  }
  setOpenSuccess(value: boolean) {
    BaggingController.setOpenSuccess(value);
  }

  // failed for create
  get isFailed(): boolean {
    return BaggingController.isFailed;
  }
  setFailed(value: boolean) {
    BaggingController.setFailed(value);
  }

  openConfirmationLeave = false;
  openConfirmationSave = false;
  answer = false;
  newPath = "";
  handleConfirmationLeave(value: boolean) {
    this.openConfirmationLeave = value;
  }
  handleConfirmationSave(value: boolean) {
    this.openConfirmationSave = value;
  }
  goBack() {
    this.$router.push("/handover");
  }
  onOpenClosePage(to: any) {
    this.handleConfirmationLeave(true);
    this.newPath = to.path;
  }
  handlerClose() {
    removeDataLocalStorage("handover");
    this.handleConfirmationLeave(false);
    this.answer = true;
    this.$router.push(this.newPath);
  }
  handlerCancelClose() {
    this.answer = false;
    this.handleConfirmationLeave(false);
  }
  onCloseSuccess() {
    this.goBack();
  }
}
